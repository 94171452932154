import React, { useEffect, useState, useMemo } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'

import JourneyAutocomplete from 'components/Pages/CustomisationJourney/JourneyAutocomplete'
import produce from 'immer'
import { ChipsToShow } from 'components/Helpers/ChipsToShow'
import {
  handleChipClick,
  handleCountryCheck,
  handleRegionCheck,
  updateProduct,
  updateProductDetails
} from 'utils/countryAndRegions'
import { countriesData } from 'components/Pages/CustomisationJourney/countries'
import { countriesAndRegionsCreate } from 'components/Pages/UserAccount/UserInformation/components/AreasOfInterest'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { londonMarketVirtualRegion } from 'components/Pages/HomePage/components/filtersData'

const useStyles = makeStyles(theme => ({
  browseMagellanTab: {
    '& h4, h6': {
      color: 'white'
    },
    '& hr': {
      background: '#fff',
      opacity: 0.5,
      margin: '0 20px',
      width: 1,
      [theme.breakpoints.down('sm')]: {
        width: 155,
        margin: '20px 0'
      }
    },
    '& >h6': {
      marginBottom: 20
    }
  },
  chipsBox: {
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'auto',
    '& .chips-container': {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  modalHeading: {
    position: 'relative',

    fontSize: 24,
    fontWeight: 700,

    '& img': {
      cursor: 'pointer',
      position: 'absolute',
      right: -12,
      top: -20
    }
  },
  magellanChipsBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& .MuiGrid-root.MuiGrid-container': {
        flexDirection: 'inherit'
      }
    },
    '& >div': {
      width: '30%',
      [theme.breakpoints.up(1550)]: {
        width: 420
      },
      [theme.breakpoints.down('sm')]: {
        width: '60%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& h4': {
      marginBottom: 15
    }
  },
  browseMagChips: {
    transition: '0.3s ease',
    width: 'max-content',
    border: '1px solid #00A3FF',
    background: 'rgba(0, 163, 255, 0.1)',
    borderRadius: 20,
    padding: '2px 18px',
    fontWeight: 400,
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 10,
    height: 28,
    '&:hover': {
      background: 'rgba(0, 163, 255, 0.3)',
      color: '#000'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      marginBottom: 5,
      '&:hover': {
        background: '#35AAEC',
        color: '#fff'
      }
    }
  },
  selected: {
    background: '#35AAEC',
    color: '#fff'
  },

  modal: {
    position: 'relative',
    color: '#000',

    '& h1': {
      fontSize: 36,
      lineHeight: '43.2px'
    },
    '& >h6, h4': {
      lineHeight: '24px'
    },
    '& h3': {
      lineHeight: '32px'
    },
    [theme.breakpoints.down(1000)]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 35px'
    }
  },
  selectorBox: {
    '& >div': {
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0
    },
    '& hr': {
      width: '33%',
      [theme.breakpoints.down(660)]: {
        width: '25%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '15%'
      }
    }
  },
  btnBox: {
    marginTop: 40,
    '& button:first-child': {
      marginRight: 10,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
        marginRight: 0
      }
    }
  }
}))
const CountriesChipInput = () => {
  const classes = useStyles()
  const {
    checkedCountries,
    setFilterValues,
    isUnlimitedSearches,
    sendFreeUserFilters,
    setFreeUserSelectedFilters,
    setSelectedFilters,
    setFreeUserFilters,
    homePageFilters,
    filterValues
  } = useFilterStateContext()
  const [searchCheckedCountries, setSearchCheckedCountries] = useState([])
  const [dataDetails, setDataDetails] = useState({
    regionsAndCountries: []
  })
  const filterName = 'geographiesWithImplementations'
  const classType = 'regionsAndCountries'
  const checkedCountriesArr = useMemo(
    () => searchCheckedCountries.map(i => i.children)
      .flat()
      .filter(i => i !== londonMarketVirtualRegion.name),
    [searchCheckedCountries]
  )
  const allSelectedItems = searchCheckedCountries
    .filter(i => !i.selected)
    .map(i => i.children)
    .flat()
    .filter(i => i !== londonMarketVirtualRegion.name);
  const selectedRegion = searchCheckedCountries.filter(i => i.selected)

  useEffect(() => {
    if (checkedCountries.length > 0) {
      setSearchCheckedCountries(checkedCountries)
    } else {
      setSearchCheckedCountries(
        countriesAndRegionsCreate({
          countriesData,
          dataDetails,
          checkedCountries
        })
      )
    }
    if (dataDetails[classType]?.length === 0) {
      setDataDetails({
        regionsAndCountries: checkedCountries.map(i => i.children).flat()
      })
    }
  }, [checkedCountries, classType, setSearchCheckedCountries])

  const removeSelectedItem = (type, name) => {
    const selectedValue = name ? name : type
    if (dataDetails[classType]?.includes(selectedValue)) {
      const filtered = dataDetails[classType].filter(
        item => item !== selectedValue
      )
      updateProduct(classType, filtered)
    } else {
      updateProductDetails(classType, [selectedValue], setDataDetails)
    }
  }

  const handleItemClick = name => {
    if (dataDetails[classType]?.includes(name)) {
      const filtered = dataDetails[classType].filter(item => item !== name)

      setDataDetails(prev =>
        produce(prev, draftState => {
          draftState[classType] = filtered
        })
      )
    } else {
      updateProductDetails(classType, [name], setDataDetails)
    }
  }

  const handleCountryClick = countryName => {
    const countryRegion = countriesData.filter(country => {
      return country.children.some(i => i.value === countryName)
    })

    const region = countryRegion[0].region
    handleItemClick(countryName)
    handleCountryCheck(
      countryName,
      region,
      countriesData,
      setSearchCheckedCountries,
      searchCheckedCountries
    )
  }
  const ChipInputChangeHandler = (filterName, value) => {
    const cleanValue = value.filter(c => c !== londonMarketVirtualRegion.name);
    const londonMarket = value.find(c => c === londonMarketVirtualRegion.name);
    setFilterValues({
      ...filterValues,
      [filterName]: cleanValue,
      ...(londonMarket ? {
        'londonMarketRelevance': ['Relevant', 'Focussed'],
      }: {})
    })
  }

  useEffect(() => {
    if (isUnlimitedSearches) {
      if (checkedCountriesArr.length) {
        ChipInputChangeHandler(filterName, checkedCountriesArr)
      }

      if (checkedCountriesArr?.length === 0 && filterValues[filterName]?.length) {
        setDataDetails({
          regionsAndCountries: checkedCountriesArr
        })

        ChipInputChangeHandler(filterName, checkedCountriesArr)
      }
    } else {
      setFreeUserFilters(prev =>
        produce(prev, draftState => {
          draftState[filterName] = checkedCountriesArr
        })
      )
      setSelectedFilters(prev => ({
        ...prev,
        geographiesWithImplementations: checkedCountriesArr
      }))
    }
  }, [isUnlimitedSearches, checkedCountriesArr])

  useEffect(() => {
    if (!isUnlimitedSearches && Object.keys(homePageFilters).length > 0) {
      const sFilters = {
        ...homePageFilters,
        [filterName]: {
          values: homePageFilters[filterName]
        }
      }
      sendFreeUserFilters(homePageFilters)
      setFreeUserSelectedFilters(sFilters)
    }
  }, [
    homePageFilters,
    isUnlimitedSearches,
    setFreeUserSelectedFilters
  ])
  return (
    <Grid className={classes.modal}>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.selectorBox}
      >
        <JourneyAutocomplete
          productDetails={countriesData}
          setProductDetails={removeSelectedItem}
          id={classType}
          dataDetails={dataDetails}
          countriesData={countriesData}
          handleChipClick={handleChipClick}
          placeholder="Enter country name"
          handleCountryCheck={handleCountryCheck}
          handleRegionCheck={handleRegionCheck}
          checkedCountries={checkedCountriesArr}
          checkedCountriesObj={searchCheckedCountries}
          handleClick={handleItemClick}
          setCheckedCountries={setSearchCheckedCountries}
          classType={classType}
          setDataDetails={setDataDetails}
          searchIcon={true}
        />
        <Box className={classes.chipsBox}>
          <ChipsToShow
            handleChipClick={handleChipClick}
            selectedRegion={selectedRegion}
            type={classType}
            dataDetails={dataDetails}
            setDataDetails={setDataDetails}
            countriesData={countriesData}
            setCheckedCountries={setSearchCheckedCountries}
            onClick={handleCountryClick}
            checkedCountries={searchCheckedCountries}
            isFromJourney={true}
            editable={true}
            handleRegionCheck={handleRegionCheck}
            selectedItems={allSelectedItems}
            count={5}
            marginTop="sm"
            btnLabel="See"
          />
        </Box>
      </Grid>
    </Grid>
  )
}
export default CountriesChipInput
