import { Box, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import CaseStudyTitle from './CaseStudyTitle'
import Chip from '../../../../../Helpers/Chip'
import { isEmpty, isArray } from 'lodash';

const useStyles = makeStyles(theme => ({
  headerRoot: {
    color: theme.palette.primary.grey,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 0',
  },
  doneSharpIconRoot: {
    fontSize: 'inherit'
  },
  listItemIconRoot: {
    minWidth: '20px',
    paddingTop: '2px',

  },
  listItemRoot: {
    alignItems: 'start',
    padding: 0
  },
  listItemTextPrimary: {
    fontSize: 'inherit',
  },
  listItemTextRoot: {
    marginTop: 0,
  },
  caseStudyContainer: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    alignItems: 'baseline',
    '& > div': {
      padding: '5px 0 5px 0',
    },
    '& > div:nth-child(odd)': {
      whiteSpace: "nowrap",
      paddingRight: 20,
    }
  },
}))

const CaseStudy = ({ caseStudy }) => {
  const classes = useStyles()

  if (!caseStudy) return null;

  let caseStudyData = [
    {
      label: "Description",
      value: caseStudy.description || '',
    },
    {
      label: 'Country of Implementation',
      value: isArray(caseStudy.countryOfImplementation) ?
        caseStudy.countryOfImplementation.filter(country => country).join(', ') :
        caseStudy.countryOfImplementation || '',
    },
    {
      label: 'Related Technologies',
      value: caseStudy.relatedTechnologies?.length > 0 && caseStudy.relatedTechnologies?.map((item, index) => (
        <Chip key={index} label={item} />
      )),
    },
  ]
  caseStudyData = caseStudyData.filter(({ value }) => !isEmpty(value))

  const date = caseStudy.datePublished && new Date(caseStudy.datePublished)
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

  return (
    <Box>
      {(date || caseStudy.mainFunction) && (
        <Box component="div" className={classes.headerRoot}>
          <Box component="div">{date}</Box>
          <Box component="div">{caseStudy.mainFunction || ''}</Box>
        </Box>
      )}

      <CaseStudyTitle title={caseStudy.articleTitle || caseStudy.articleUrl} url={caseStudy.articleUrl} />

      <div className={classes.caseStudyContainer}>
        {caseStudyData.map((caseStudyItem, index) => (<>
          <div key={index}>
            <Typography variant='h5'>
              {caseStudyItem.label}
            </Typography>
          </div>
          <div>
            {caseStudyItem.value}
          </div>
        </>))}
      </div>
    </Box>
  )
}

CaseStudy.propTypes = {
  caseStudy: PropTypes.shape({
    articleTitle: PropTypes.string,
    articleUrl: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    datePublished: PropTypes.string,
    keyTakeaways: PropTypes.arrayOf(PropTypes.string),
    relatedTechnologies: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default CaseStudy
